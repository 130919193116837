<template>
  <div class="">
    <div>
      <div class="card">
        <div class="card-header">
          <h4 class="mb-0">{{ i18n['magazine'].tcListOfMagazines }}</h4>
        </div>
        <div class="card-body py-3 flex-grow-1">
          <b-row class="my-4">
            <b-col></b-col>
            <b-col></b-col>
            <b-col>
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  :placeholder="i18n['magazine'].tcTypeToSearch"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">{{ i18n['magazine'].tcClear }}</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
          <b-table
            striped
            hover
            :items="msg.magazine"
            sort-by="maz_start_date"
            sortDesc
            :fields="fields"
            class="table2"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template v-slot:cell(view_file)="row">
              <button class="btn btn-dark flex-0 mr-2" @change="row.toggleDetails" @click="changePdf(row)">
                {{ i18n['magazine'].tcViewFile }}
              </button>
              <button class="btn btn-dark flex-0 mr-2" @change="row.toggleDetails" @click="downloadPDF(row)">
                {{ i18n['magazine'].tcDownload }}
              </button>
            </template>
            <template v-slot:cell(maz_start_date)="row">
              <span>{{ row.item.maz_start_date }}</span>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-text="⏮"
            prev-text="⏪"
            next-text="⏩"
            last-text="⏭"
            class="mt-4"
            pills
            size="md"
            aria-controls="my-table"
          >
          </b-pagination>
        </div>
        <b-row
          class="my-4"
          v-if="
            userId != null &&
            (userId == 'cd50b29d-1ba8-4bcb-ad84-0aa126c59677' || userId == 'a538d613-8d4f-4056-87eb-13e459420399')
          "
        >
          <b-col></b-col>
          <b-col></b-col>
          <b-col>
            <button type="button" class="btn btn-primary btn-icon" @click="moveToAdmin">
              <span class="btn-inner--icon">
                <i class="far fa-user"></i>
              </span>
              <span class="btn-inner--text">{{ i18n['magazine'].tcAdminForMagazine }}</span>
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import constantData from '@/json/data.json'

export default {
  name: 'magazine',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          magazine: {
            tcAdminForMagazine: 'Admin For Magazine',
            tcClear: 'Clear',
            tcDownload: 'Download',
            tcListOfMagazines: 'List of Magazines by Year',
            tcTypeToSearch: 'Type to Search',
            tcViewFile: 'View File',
            tcFileName: 'File Name',
            tcTitleName: 'Title Name',
            tcDate: 'Date',
            tcYear: 'Year',
            tcMonth: 'Month',
          },
        }
      },
    },
    msg: Object,
  },
  data() {
    return {
      items: this.msg.magazine,
      file: null,
      filter: null,
      filterOn: [],
      perPage: 5,
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters({
      userId: 'user/userId',
    }),
    rows() {
      if (this.msg.magazine != null) {
        return this.msg.magazine.length
      } else {
        return 0
      }
    },
    fields() {
      // Note `isActive` is left out and will not appear in the rendered table
      return [
        {
          key: 'maz_file_name',
          label: this.i18n['magazine'].tcFileName,
          sortable: true,
        },
        {
          key: 'maz_title_name',
          label: this.i18n['magazine'].tcTitleName,
          sortable: true,
        },
        {
          key: 'maz_start_date',
          label: this.i18n['magazine'].tcDate,
          sortable: true,
        },
        {
          key: 'year',
          label: this.i18n['magazine'].tcYear,
          formatter: (value, key, item) => {
            let mydate = new Date(item.maz_start_date)
            return mydate.getFullYear().toString()
          },
          sortable: true,
        },
        {
          key: 'month',
          label: this.i18n['magazine'].tcMonth,
          formatter: (value, key, item) => {
            let mydate = new Date(item.maz_start_date)
            return mydate.toLocaleString('default', { month: 'long' })
          },
          sortable: true,
        },
        {
          key: 'view_file',
          label: '',
        },
      ]
    },
  },
  methods: {
    moveToAdmin() {
      this.$router.push({ path: '/programs/magazine/admin' })
    },
    changePdf(item) {
      // let routeData = this.$router.resolve({ name: 'pdfpreview', query: { filename: item.item.maz_file_name } })
      let routeData = this.$router.resolve({ path: '/programs/magazine/pdfpreview/a', query: { filename: item.item.maz_file_name } })
      window.open(routeData.href, '_blank')
    },
    downloadPDF(item) {
      let fileUrl = constantData.magazine_prefix + item.item.maz_file_name
      let fileLink = document.createElement('a')
      fileLink.href = fileUrl
      fileLink.setAttribute('download', item.item.maz_file_name)
      fileLink.target = '_blank'
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    fileChange(event) {
      this.file = event.target.files[0]
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.btn.btn-dark.flex-0.mr-2 {
  padding: 12px 10px;
}

h3 {
  line-height: 1;
}

.page-header {
  @include breakpoint(sm) {
    flex-wrap: wrap;
    h1 {
      width: 100%;
    }
    .btn {
      width: 100%;
    }
  }
}

.page-body {
  padding: 40px 15px 80px 15px;
}

.bg-block {
  padding: 45px 40px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1,
.section-2,
.section-3 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
    .col {
      @include breakpoint(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.section-1 {
  .d-flex {
    margin-left: -15px;
    margin-right: -15px;
    @include breakpoint(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .col {
    &:nth-of-type(1) {
      width: 20%;
    }
    &:nth-of-type(2) {
      width: 20%;
    }
    &:nth-of-type(3) {
      width: 25%;
    }
    &:nth-of-type(4) {
      width: 35%;
    }
  }
}

.section-1-notes {
  min-height: 87px;
  @include breakpoint(sm) {
    min-height: 66px;
  }
}

hr {
  margin: 68px 0 34px;
  @include breakpoint(sm) {
    margin: 40px 0;
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.members {
  flex-wrap: wrap;
  .member {
    max-width: 33.3333%;
    margin-bottom: 40px;
    padding-right: 15px;
    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
    }
    h3 {
      margin-bottom: 28px;
      font-family: $open-sans;
      font-size: 18px;
      letter-spacing: 0.6px;
      line-height: 24px;
    }
    div {
      margin-bottom: 15px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 22px;
      &:last-of-type {
        margin-bottom: 0;
      }
      svg {
        margin-right: 16px;
      }
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
