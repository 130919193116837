<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcGideonsMagazine }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"/>
    <div v-if="allowPage">
      <div class="magazine-home">
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
        <div class="container">
          <Magazine :msg="items" :i18n="translations.components"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import Magazine from '@/components/magazine.vue'
import magservice from '@/services/magazineService.js'
import SecurityBanner from '@/components/SecurityBanner'
import 'vue-loading-overlay/dist/vue-loading.css'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'magazine-home',
  mixins: [translationMixin],
  components: {
    Loading,
    Magazine,
    securityBanner: SecurityBanner,
  },
  data() {
    return {
      translations: {},
      items: {},
      isLoading: true,
      fullPage: true,
    }
  },
  computed: {
    ...mapGetters({
      // isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture'
    }),
    allowPage() {
      return true
      // return this.$route.path.charAt(this.$route.path.length - 1) === '/'
      //   ? this.isInMenu(this.$route.path.slice(0, -1))
      //   : this.isInMenu(this.$route.path)
    },
  },
  async created() {
    this.isLoading = true
    await Promise.all([
      this.getComponentTranslations('magazine', 'security-banner'),
      magservice.getALLMagazine()
    ]).then((results) => {
      const componentTranslations = results[0]
      this.$set(this.translations, 'components', componentTranslations)
      this.isLoading = false
      this.items = results[1].data
    })
  },
}
</script>

<style lang="scss">
.magazine-home {
  margin-bottom: 30px;
}
</style>
